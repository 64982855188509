

$(function () {

  // remove messages on size click
  $('.orderButtonDiv, .preOrderButtonDiv').on('click', function () {
    $('.no-stock-message').remove();
    $('.personalisation__message').hide();
  });
  // show item not available message on click of greyed out size button
  $('.noLongerStockedButtonDiv').on('click', function () {
    $('.no-stock-message').remove();
    $('#floatStockWarning').addClass('hidden');
    $('#free-uk-express-message').addClass('hidden');
    $('#ready-for-dispatch-message').addClass('hidden');
    $(this)
      .closest('.select-item-size-buttons')
      .after(
        '<div class="no-stock-message">' +
        jslocalstrings['This option is out of stock'] +
        '</div>'
      );
  });

  $('.noDeliveryButtonDiv').on('click', function () {
    $('.no-delivery-variant-selected').addClass('no-delivery-variant');
    $('.no-delivery-variant-selected').removeClass(
      'no-delivery-variant-selected'
    );

    $(this)
      .find('.no-delivery-variant')
      .addClass('no-delivery-variant-selected');
    $(this).find('.no-delivery-variant').removeClass('no-delivery-variant');
    $('.no-stock-message').remove();
    $('#floatStockWarning').addClass('hidden');
    $('#free-uk-express-message').addClass('hidden');
    $('#ready-for-dispatch-message').addClass('hidden');
    $(this)
      .closest('.select-item-size-buttons')
      .after(
        '<div class="no-stock-message">' +
        jslocalstrings[
        'Only available for purchase in store, please call 01935 414142 for more information.'
        ] +
        '</div>'
      );
  });

  // hide add to basket confirmation and warning panel if selecting new size
  $('.orderButtonDiv, .preOrderButtonDiv').on('click', function () {
    $('#add-to-basket-success').removeClass('active');
    $('#add-to-basket-warning').removeClass('active');
  });

  // set the add to basket button to green (active) if only one size avalable
  if ($('.orderButtonSelected').length == $('.size-block').length) {
    $('#addToCartLink')
      .addClass('active')
      .removeClass('pre-order')
      .html(
        jslocalstrings['Add to basket']
      );
  }

  // width and text of size selection buttons on product page
  resizeProductSizeSelectButtons();

  // if the size selects are displaying as a select box...
  //show the select size buttons on variants header click
  $(document).on("click", ".select-variants-header", function () {
    $(this).next(".select-item-size-buttons").toggleClass("active");
  });
  // Show the selected value in the header div
  $(document).on("click", ".select-item-size-buttons a", function () {
    $(this)
      .closest(".variants-list")
      .find(".select-variants-header")
      .text($("span", this).text());
    $(this).closest(".select-item-size-buttons").removeClass("active");
  });

  $(".select-item-size-buttons .orderButtonDiv a").on('click', function () {
    $(".add-to-basket-product__size").find(".add-basket-price-value").remove();
    $(".add-to-basket-product__size").append(
      '<span class="add-basket-price-value">' +
      $(this).find("span").text() +
      "</span>"
    );
  });

});


function resizeProductSizeSelectButtons() {
  var charCount = 0;
  var formattedSize;
  $('.orderButtonDiv').each(function () {
    if ($('a', this).text().length > charCount) {
      charCount = $('a', this).text().length;
    }
  });
  // if any button has more than 5 chars, double the button size
  if (charCount > 5) {
    $('.orderButtonDiv').addClass('col-2');
  }
}

function updateOrderInfo(info, lowStock) {
  // set the add to cart button color & text
  if ($('.orderButtonSelected').length == $('.size-block').length) {
    $('#addToCartLink')
      .addClass('active')
      .removeClass('pre-order')
      .html(
        jslocalstrings['Add to basket']
      );
  }

  // preorder item - show message and change button
  var preorder = info.match(/PREORDER/);
  if (preorder) {
    info = info.replace('PREORDER', '');
    if (document.getElementById('dueDate')) {
      document.getElementById('dueDate').innerHTML = document.getElementById(
        'dueDate'
      ).innerHTML = info;
    }
    $('#preorderwarningpanel').removeClass('hidden');
    // change color of add to cart button for pre orders
    $('#addToCartLink')
      .addClass('pre-order')
      .removeClass('active')
      .html(
        jslocalstrings['Pre-order now']
      );
    $('#preorderwarningpanel').removeClass('available-soon');
  } else {
    $('#preorderwarningpanel').removeClass('available-soon');
    $('#preorderwarningpanel').addClass('hidden');
  }

  // low stock item - show message
  if (lowStock) {
    $('#floatStockWarning').removeClass('hidden');
  } else {
    $('#floatStockWarning').addClass('hidden');
  }

  var isFreeUKExpressDelivery;
  if ($('#free-uk-express-message').length) {
    isFreeUKExpressDelivery = 1;
    // move the message into the showsizes block
    $('#free-uk-express-message')
      .closest('.product-messages')
      .appendTo('.size-block');
    if (!preorder && !lowStock) {
      $('#free-uk-express-message').removeClass('hidden');
    } else {
      $('#free-uk-express-message').addClass('hidden');
    }
  }

  // show the ready for dispatch message
  if (!preorder && !lowStock && !isFreeUKExpressDelivery) {
    $('#ready-for-dispatch-message').removeClass('hidden');
  } else {
    $('#ready-for-dispatch-message').addClass('hidden');
  }

  // hide the no size selected warning
  $('.noSizeSelectedWarning').hide();
}

function personaliseProduct(page) {
  // if the 'no size selected' box is showing then cancel
  if ($('.noSizeSelectedWarning').css('display') == 'block') {
    return;
  }
  if (isAddToCartLoading()) {
    return;
  }

  var isSDPersn = $('input[type=hidden][name=isSDPersn]').val();
  if (isSDPersn) {
    var persnServicesErrorMessage = LovellPersn.getFormSubmitErrorMessage();
    if (persnServicesErrorMessage) {
      $('.persn-message__invalid-persn')
        .css('display', 'block')
        .find('.persn-message__message-content')
        .text(persnServicesErrorMessage);
    } else {
      var persnServices = LovellPersn.getServiceValuesString();
      if (persnServices) {
        if (textIsAllowed(persnServices)) {
          LovellPersn.showPersonalisationTermsModal();
        }
      } else {
        addProductToBasket(page);
      }
    }
  } else {
    addProductToBasket(page);
  }
}

function addProductToBasket(page) {
  if (page == 'BASKET_PAGE') {
    updateBasketItem();
  } else {
    updateBasket('', '', '');
  }
}

function noPersnButton() {
  $('#pText1').val('');
  $('#pText2').val('');
  $('#prsnl-confirm-panel').modal('hide');
}

var limit = new Array();
var available = new Array();
var inStock = new Array();
var stockDate = new Array();

var addToCartSize = '';

function forCart(action, data, productIndex) {
  var info = '&nbsp;';
  var idName = makeValidId('s_' + addToCartSize);
  $('#product-stock-warning').hide();

  // If user has selected a size
  if (action == 'size') {
    // Deselect any currently selected sizes
    // productIndex is used to only affect the correct size block in the case of product bundles
    if (addToCartSize) {
      $('#size-block-' + productIndex + ' .orderButtonSelected').addClass('orderButton');
      $('#size-block-' + productIndex + ' .orderButtonSelected').removeClass('orderButtonSelected');
      $('#size-block-' + productIndex + ' .no-longer-stocked-variant-selected').removeClass('no-longer-stocked-variant-selected');
    }
    addToCartSize = data;
    idName = makeValidId('s_' + addToCartSize);
    if ($('#size-block-' + productIndex + ' #' + idName).hasClass('no-longer-stocked-variant')) {
      $('#size-block-' + productIndex + ' #' + idName).addClass('no-longer-stocked-variant-selected');
    }
    else {
      $('#size-block-' + productIndex + ' #' + idName).addClass('orderButtonSelected');
      $('#size-block-' + productIndex + ' #' + idName).removeClass('orderButton');
    }

    var sizeData = data;
    $('#ba_Size').val(sizeData);
  }
  if (checkSizesSelected()) {
    $('#addToCartLink')
      .addClass('active')
      .html(
        jslocalstrings['Add to basket']
      );
  }
  else {
    $('#addToCartLink').removeClass('active').removeClass('pre-order');
  }

  var quantity = parseInt($('#ba_Quantity').val());
  if (!quantity) {
    quantity = 1;
  }
  if (action == 'qty') {
    quantity += data;
  }
  if (quantity < 1) {
    quantity = 1;
  }
  var availableToAdd = 999;
  var availableNow = 999;
  var orderLimit = 999;

  $('.size-block').each(function (index) {
    var blockIndex = index + 1;
    var selectedSize = $(this).find('.orderButtonSelected').attr('id');

    orderLimit = limit[blockIndex + '-' + selectedSize];
    var availableToAddForSize = available[blockIndex + '-' + selectedSize];
    var availableNowForSize = inStock[blockIndex + '-' + selectedSize];
    if (availableToAddForSize < availableToAdd)
      availableToAdd = availableToAddForSize;
    if (availableNowForSize < availableNow) availableNow = availableNowForSize;
  });

  var stockDue = stockDate[idName];
  var heldInStock = parseInt($('#heldInStock').val());
  if (document.getElementById('ba_Quantity')) {
    document.getElementById('ba_Quantity').className = 'orderInput';
  }
  if (
    quantity > availableNow &&
    quantity <= availableToAdd &&
    quantity > 1 &&
    heldInStock
  ) {
    document.getElementById('ba_Quantity').className = 'orderInputAlert';

    info = '';
    if (availableNow < 1) {
      info += jslocalstrings['None'];
    } else {
      info += jslocalstrings['Only'] + ' ' + availableNow;
    }

    if (availableNow == 1) {
      info += ' ' + jslocalstrings['is available now in size'];
    } else {
      info += ' ' + jslocalstrings['are available now in size'];
    }

    var onlyAvailableSize = document.getElementById(idName).innerHTML;
    info +=
      ' ' +
      onlyAvailableSize +
      '. ' +
      jslocalstrings['New stock, expected'] +
      ' ' +
      stockDue +
      ', ' +
      jslocalstrings['can be pre-ordered.'];
  }

  if (availableToAdd < 0) availableToAdd = 0;

  var tradeAccount = $('tradeAccount').val();
  if (quantity > availableToAdd && heldInStock) {
    document.getElementById('ba_Quantity').className = 'orderInputAlert';
    // Prevent adding more than we have in stock
    if (!availableToAdd) {
      info =
        jslocalstrings['This item is out of stock in size'] +
        ' ' +
        addToCartSize.replace(/\.5/g, '&frac12') +
        '.';
    } else {
      if (availableToAdd == 1) {
        info += jslocalstrings['There is only'];
      } else {
        info += jslocalstrings['There are only'];
      }
      var onlyAvailableSize = document.getElementById(idName).innerHTML;
      if ($('.size-block').length > 1) {
        info +=
          ' ' +
          availableToAdd +
          ' ' +
          jslocalstrings['of this item available for the sizes selected.'];
      } else {
        info +=
          ' ' +
          availableToAdd +
          ' ' +
          jslocalstrings['of this item available in size'] +
          ' ' +
          onlyAvailableSize +
          '.';
      }
    }
    quantity = availableToAdd;

    if (availableToAdd == orderLimit && !tradeAccount) {
      // We may have more in stock that we don't want to advertise to competitors
      $('#product-stock-warning .text').html(
        jslocalstrings['Please contact us for larger orders'] + '.'
      );
    } else {
      $('#product-stock-warning .text').html(info);
    }
    $('#product-stock-warning').css('display', 'flex');
  }

  if (document.getElementById('ba_Quantity')) {
    document.getElementById('ba_Quantity').value = quantity;
  }
}

function canAdd(onOff) {
  // Highlight add-to-cart button according to size selection
  if (!checkSizesSelected()) {
    if (onOff) {
      $('#addThisProductToBasket .noSizeSelectedWarning').show();
    } else {
      $('.noSizeSelectedWarning').hide();
    }
  }
}

function checkSizesSelected() {
  var orderButtonsSelected = $('.orderButtonSelected').length;
  var sizeBlocksWithButtons = $('.size-block').length;
  var incorrectQuantities = 0;

  $('.size-block').each(function () {
    var bundleQuantity = $(this).find('.bundle-quantity').val();
    if (bundleQuantity > 1) {
      sizeBlocksWithButtons -= 1;
      var totalQuantity = $(this).find('.total').html().split(' ')[0];
      if (bundleQuantity != totalQuantity) {
        incorrectQuantities = 1;
        $(this).find('.total').css('color', '#e86107');
      }
    }
  });

  return orderButtonsSelected != sizeBlocksWithButtons || incorrectQuantities
    ? 0
    : 1;
}

// Check to see if the 'Add to basket' button is loading
function isAddToCartLoading() {
  var $el = $('#addToCartLink');
  return $el.data('loading');
}

function changeUnits(sizeUnit, sizeBlockId, productId) {
  // if there is a custom variant we'll already have the relevant translated options on the page, so do nothing.
  for (var x = 0; x < unitsList.length; x++) {
    var $unitSelector = $('#' + makeValidId('unitSelector-' + unitsList[x]));
    if (unitsList[x] == sizeUnit) {
      $unitSelector.addClass(sizeUnitData.unitsButtonClass_selected);
      $('currentSizeScale').val(sizeUnit);
    } else {
      $unitSelector
        .addClass(sizeUnitData.unitsButtonClass)
        .removeClass(sizeUnitData.unitsButtonClass_selected);
    }
  }

  var itemType = sizeUnitData.itemType;
  $('#addToCartLink').attr('href', sizeUnitData.javascriptLink);
  // If we're looking at footwear (only) remember user's preference for size scale immediately
  if (
    itemType == 'Boots' ||
    itemType == 'Running Shoes' ||
    itemType == 'Trainers'
  ) {
    doAjaxAction({
      action: 'UPDATE_VISIT_SIZE_SCALE',
      ItemType: itemType,
      SizeScale: sizeUnit,
    });
  }

  var productSizeData = sizesData[productId];
  var productSizeList = sizesList[productId];
  $('#' + sizeBlockId + ' .size').each(function (x) {
    var $sizeSelector = $(this);
    var $stockSizeSelector = $(
      '#' + makeValidId('stockSize_' + productSizeList[x])
    );
    if ($sizeSelector.length) {
      var displaySize = '';
      if (productSizeData[productSizeList[x] + ':' + sizeUnit]) {
        displaySize = productSizeData[productSizeList[x] + ':' + sizeUnit];
      } else if (sizeUnit == 'UK') {
        displaySize = productSizeList[x];
      } else if (
        $sizeSelector
          .html()
          .match(new RegExp('^' + sizeUnitData.originalUnit + ':')) == false
      ) {
        displaySize = productSizeList[x];
      } else {
        // Catch all
        displaySize = productSizeList[x];
      }
      displaySize = formatDisplaySize(displaySize, 0);
      $sizeSelector.html(displaySize);
      $stockSizeSelector.html(displaySize);
    }
  });
}