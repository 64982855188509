// **********************************************************
//JS: lovell-account-page.js
// Lovell Rugby website responsive account page functions - mostly deals with showing/hiding elements on the screen
// (c) 2015 Lovell Rugby Limited

$(document).ready(function () {
  // Hides display of information upon click of edit button
  $('.addresses .edit').on('click', function () {
    $('.address-display').hide(0);
    // Scroll back up to the top of the page
    var scrollToHeight =
      $('.addresses').closest('.account-page__section').offset().top -
      (parseInt($('body').css('padding-top')) + 10);
    $('html, body').animate({ scrollTop: scrollToHeight }, 500);
  });

  // Open 'add new delivery address' form
  $('.addresses .add').on('click', function () {
    $('.find-address-button').addClass('disabled');
    $('#delivery-address-new-form').show(0);
    $('.address-display').hide(0);
    // Scroll back up to the top of the page
    var scrollToHeight =
      $('#delivery-address-new-form').closest('.account-page__section').offset()
        .top -
      (parseInt($('body').css('padding-top')) + 10);
    $('html, body').animate({ scrollTop: scrollToHeight }, 500);
    // Hide the back to home button
    $('.back-to-home').hide(0);
    // Hides 'update' button, shows 'Save' button
    $('#delivery-address-new-form .update-button').toggle(0);
  });

  $('.postcode').on('input propertychange paste', function () {
    if ($(this).val().length > 5) {
      $('.find-address-button').removeClass('disabled');
    } else {
      $('.find-address-button').addClass('disabled');
    }
  });

  $('.find-address-button').on('click', function (e) {
    e.preventDefault();
    var AddressID = $(this).data('addressid');
    searchPAFAddresses(
      $('#del_add_' + AddressID + '_Postcode').val(),
      $('#del_add_' + AddressID + '_Building').val(),
      'del_add_' + AddressID + '_',
      1
    );
  });

  $('.full-address-link').on('click', function () {
    $('.full-address-link').hide();
    $('.find-address').hide();
    $('.optional-house').hide();
    $('.postcode-search-link').show();
    $('.full-address').show();
  });

  $('.postcode-search-link').on('click', function () {
    $('.full-address-link').show();
    $('.find-address').show();
    $('.optional-house').show();
    $('.postcode-search-link').hide();
    $('.full-address').hide();
  });
});

function clearCountyValue(IdPrefix) {
  // reset the UK county selector
  $('#' + IdPrefix + 'UKCountySelector')
    .val(0)
    .change();
  // reset the Irish county selector
  $('#' + IdPrefix + 'irishCountySelector')
    .val(0)
    .change();
  // reset the USA State selector
  $('#' + IdPrefix + 'USAStateSelector')
    .val(0)
    .change();
  // clear the current county val
  $('#' + IdPrefix + 'addCounty').val('');
}

function checkoutCountryChange(IdPrefix) {
  if ($('#' + IdPrefix + 'addCountry').length) {
    // Localise address input form for selected country, including the Ireland county selector
    var selectedCountryCode = $('#' + IdPrefix + 'addCountry').val();
    southAmericaDeliveryWarningToggle($('#' + IdPrefix + 'addCountry').val());
    if (IdPrefix == '') {
      if (selectedCountryCode == 'BR' || selectedCountryCode == 'AR') {
        // show the tax code field for Brazilian customers
        $('.field-tax-payer-id').removeClass('hidden');
      } else {
        $('.field-tax-payer-id').addClass('hidden');
      }
    }
    if (
      selectedCountryCode == 'GB' ||
      selectedCountryCode == 'IE' ||
      selectedCountryCode == 'US'
    ) {
      $('#' + IdPrefix + 'countyDiv').hide();
      // if using the responsive site, the select box has been replaced by a jQuery plugin to a styleable div - we need to show that here, not the selectbox
      if (selectedCountryCode == 'GB') {
        //show UK county selector
        if (
          $('#' + IdPrefix + 'UKCountySelector').closest('.sod_select').length
        ) {
          $('#' + IdPrefix + 'UKCountySelector')
            .closest('.sod_select')
            .show();
        } else {
          $('#' + IdPrefix + 'UKCountySelector').css('display', 'inline');
        }
        //hide Ireland county selector
        if (
          $('#' + IdPrefix + 'irishCountySelector').closest('.sod_select')
            .length
        ) {
          $('#' + IdPrefix + 'irishCountySelector')
            .closest('.sod_select')
            .hide();
        } else {
          $('#' + IdPrefix + 'irishCountySelector').css('display', 'none');
        }
        // Hide USA state selector
        if (
          $('#' + IdPrefix + 'USAStateSelector').closest('.sod_select').length
        ) {
          $('#' + IdPrefix + 'USAStateSelector')
            .closest('.sod_select')
            .hide();
        } else {
          $('#' + IdPrefix + 'USAStateSelector').css('display', 'none');
        }
        // make sure the county div is visible (label + field)
        $('#' + IdPrefix + 'addCounty')
          .closest('.field-county')
          .show();
      } else if (selectedCountryCode == 'IE') {
        // hide UK
        if (
          $('#' + IdPrefix + 'UKCountySelector').closest('.sod_select').length
        ) {
          $('#' + IdPrefix + 'UKCountySelector')
            .closest('.sod_select')
            .hide();
        } else {
          $('#' + IdPrefix + 'UKCountySelector').css('display', 'none');
        }
        //show Ireland county selector
        if (
          $('#' + IdPrefix + 'irishCountySelector').closest('.sod_select')
            .length
        ) {
          $('#' + IdPrefix + 'irishCountySelector')
            .closest('.sod_select')
            .show();
        } else {
          $('#' + IdPrefix + 'irishCountySelector').css('display', 'inline');
        }
        // Hide USA state selector
        if (
          $('#' + IdPrefix + 'USAStateSelector').closest('.sod_select').length
        ) {
          $('#' + IdPrefix + 'USAStateSelector')
            .closest('.sod_select')
            .hide();
        } else {
          $('#' + IdPrefix + 'USAStateSelector').css('display', 'none');
        }
        // make sure the county div is visible (label + field)
        $('#' + IdPrefix + 'addCounty')
          .closest('.field-county')
          .show();
      } else if (selectedCountryCode == 'US') {
        // USA
        if (
          $('#' + IdPrefix + 'UKCountySelector').closest('.sod_select').length
        ) {
          $('#' + IdPrefix + 'UKCountySelector')
            .closest('.sod_select')
            .hide();
        } else {
          $('#' + IdPrefix + 'UKCountySelector').css('display', 'none');
        }
        // Hide Irish county selector
        if (
          $('#' + IdPrefix + 'irishCountySelector').closest('.sod_select')
            .length
        ) {
          $('#' + IdPrefix + 'irishCountySelector')
            .closest('.sod_select')
            .hide();
        } else {
          $('#' + IdPrefix + 'irishCountySelector').css('display', 'none');
        }
        // Show USA state selector
        if (
          $('#' + IdPrefix + 'USAStateSelector').closest('.sod_select').length
        ) {
          $('#' + IdPrefix + 'USAStateSelector')
            .closest('.sod_select')
            .show();
        } else {
          $('#' + IdPrefix + 'USAStateSelector').css('display', 'inline');
        }
        // make sure the county div is visible (label + field)
        $('#' + IdPrefix + 'addCounty')
          .closest('.field-county')
          .show();
      }
    } else {
      $('#' + IdPrefix + 'addCounty')
        .closest('.field-county')
        .show();
      $('#' + IdPrefix + 'countyDiv').show();
      // Hide UK county selector
      if (
        $('#' + IdPrefix + 'UKCountySelector').closest('.sod_select').length
      ) {
        $('#' + IdPrefix + 'UKCountySelector')
          .closest('.sod_select')
          .hide();
      } else {
        if ($('#' + IdPrefix + 'UKCountySelector').length) {
          document.getElementById(IdPrefix + 'UKCountySelector').style.display =
            'none';
        }
      }
      // Hide Ireland county selector
      if (
        $('#' + IdPrefix + 'irishCountySelector').closest('.sod_select').length
      ) {
        $('#' + IdPrefix + 'irishCountySelector')
          .closest('.sod_select')
          .hide();
      } else {
        if ($('#' + IdPrefix + 'irishCountySelector').length) {
          document.getElementById(
            IdPrefix + 'irishCountySelector'
          ).style.display = 'none';
        }
      }
      // Hide USA state selector
      if (
        $('#' + IdPrefix + 'USAStateSelector').closest('.sod_select').length
      ) {
        $('#' + IdPrefix + 'USAStateSelector')
          .closest('.sod_select')
          .hide();
      } else {
        if ($('#' + IdPrefix + 'USAStateSelector').length) {
          document.getElementById(IdPrefix + 'USAStateSelector').style.display =
            'none';
        }
      }
    }

    if (selectedCountryCode == 'GB' || selectedCountryCode == 'IE') {
      if ($('#' + IdPrefix + 'countyFieldText').length) {
        document.getElementById(IdPrefix + 'countyFieldText').innerHTML =
          jslocalstrings['County'] + ' *';
      }
      if ($('#' + IdPrefix + 'postcodeFieldText').length) {
        document.getElementById(IdPrefix + 'postcodeFieldText').innerHTML =
          jslocalstrings['Postcode'] + ' *';
      }
    } else if (selectedCountryCode == 'US') {
      if ($('#' + IdPrefix + 'countyFieldText').length) {
        document.getElementById(IdPrefix + 'countyFieldText').innerHTML =
          jslocalstrings['State'] + ' *';
      }
      if ($('#' + IdPrefix + 'postcodeFieldText').length) {
        document.getElementById(IdPrefix + 'postcodeFieldText').innerHTML =
          jslocalstrings['Zip Code'] + ' *';
      }
    } else {
      if ($('#' + IdPrefix + 'countyFieldText').length) {
        document.getElementById(IdPrefix + 'countyFieldText').innerHTML =
          jslocalstrings['Region'] + ' *';
      }
      if ($('#' + IdPrefix + 'postcodeFieldText').length) {
        document.getElementById(IdPrefix + 'postcodeFieldText').innerHTML =
          jslocalstrings['Postcode'] + ' *';
      }
    }

    // Hide AJAX PAF address search if selected country is not United Kingdom
    if (selectedCountryCode == 'GB') {
      $('#' + IdPrefix + 'addPostcodeSearchForm').show();
    } else {
      $('#' + IdPrefix + 'addPostcodeSearchForm').hide();
    }

    if ($('#in_useBillingForDelivery').length) {
      if (
        !IdPrefix &&
        document.getElementById('in_useBillingForDelivery').value == 1
      ) {
      }
    }
  }
}
